





















































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import LoyaltyInput from '../components/LoyaltyInput/index.vue'
import LoyaltyCheckbox from '../components/LoyaltyCheckbox/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import { required, minLength, requiredIf, ValidationRule } from 'vuelidate/lib/validators'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import ConfirmationIcon from '@/app/ui/assets/icon_confirmation.vue'
import { Dropdown, EnumAction } from '@/app/infrastructures/misc/Constants/loyaltyProgram'
import { LoyaltyProgramGetPointRules } from '@/domain/entities/LoyaltyProgramGetPointRules'
import controller, {
  UpdateLoyaltyProgramGetPointRulesPayload,
} from '@/app/ui/controllers/LoyaltyProgramGetPointRulesController'
import { EventBusConstants } from '@/app/infrastructures/misc'

interface ValidationsInterface {
  form: {
    userType: {
      required: () => ValidationRule;
      minLength: ValidationRule;
    }
    transactionType: {
      required: () => ValidationRule;
      minLength: ValidationRule;
    }
    shipmentType: {
      required: () => ValidationRule;
      minLength: ValidationRule;
    }
    status: {
      required: () => ValidationRule;
      minLength: ValidationRule;
    }
    amount: {
      required: () => ValidationRule;
    }
  }
  userType: {
    registered: {
      required: ValidationRule;
    }
    unRegistered: {
      required: ValidationRule;
    }
  }
}

@Component({
  mixins: [validationMixin],
  components: {
    LoyaltyInput,
    LoyaltyCheckbox,
    Button,
    LoadingOverlay,
    TextInput,
    Modal,
    ConfirmationIcon,
  },
})
export default class GetPoinFormula extends Vue {
  hasDuplicateOptions = false
  EnumAction = EnumAction
  isShipmentId = false
  controller = controller
  showModalConfirmation = false
  showModalSuccess = false
  channel: Dropdown[] = [
    {
      label: 'Customer Apps',
      value: 'customerApps',
    },
    {
      label: 'Marketplace',
      value: 'marketplace',
    },
    {
      label: 'Retail',
      value: 'retail',
    },
  ]
  userType: Record<string, boolean> = {
    registered: false,
    unRegistered: false,
  }
  form: Record<string, string | string[]> = {
    userType: [],
    transactionType: [],
    shipmentType: [],
    status: [],
    amount: '',
  }

  created(): void {
    this.controller.getPointRules()
  }

  @Validations()
  validations(): ValidationsInterface {
    return {
      form: {
        userType: {
          required,
          minLength: minLength(1),
        },
        transactionType: {
          required,
          minLength: minLength(1),
        },
        shipmentType: {
          required,
          minLength: minLength(1),
        },
        status: {
          required,
          minLength: minLength(1),
        },
        amount: {
          required,
        },
      },
      userType: {
        registered: {
          required: requiredIf(() => {
            return !this.userType.unRegistered
          }),
        },
        unRegistered: {
          required: requiredIf(() => {
            return !this.userType.registered
          }),
        },
      },
    }
  }

  get getDataPointRule(): LoyaltyProgramGetPointRules {
    return this.controller.formGetPointRule
  }

  private onClickCheckbox(name: string): void {
    if (name === 'isShipmentId') {
      this.isShipmentId = !this.isShipmentId
    } else {
      if (this.form.userType.indexOf(name) !== -1) {
        (<string[]>this.form.userType) = (<string[]>this.form.userType).filter(
          item => item !== name
        )
        this.userType[name] = false
      } else {
        ;(<string[]>this.form.userType).push(name)
        this.userType[name] = true
      }
    }
  }

  private onInputTag(e: Array<never>, type: string): void {
    this.form[type] = e.map((item: string) => item.toUpperCase())
  }

  private updatePointRule(): void {
    const payload = {
      pointRulesId: this.getDataPointRule?.pointRulesId || 0,
      isRegistered: this.userType.registered,
      isUnRegistered: this.userType.unRegistered,
      transactionType: this.form.transactionType,
      shipmentType: this.form.shipmentType,
      shipmentStatus: this.form.status,
      minimumAmount: Number(this.form.amount),
      isWithoutShipment: this.isShipmentId,
    }

    this.controller.updatePointRule(payload)
  }

  private onSaveUpdate(): void {
    this.showModalConfirmation = true
  }

  private onModalAction(type: string): void {
    if (type === EnumAction.CANCEL) {
      this.showModalConfirmation = false
    }
    if (type === EnumAction.EDIT) {
      this.showModalConfirmation = false
      this.updatePointRule()
    }
  }

  private refetchGetPointRules(): void {
    this.showModalSuccess = false
    this.controller.getPointRules()
  }

  @Watch('controller.formGetPointRule')
  onFormGetPointRule(value: UpdateLoyaltyProgramGetPointRulesPayload): void {
    this.form.shipmentType = value.shipmentType || []
    this.form.transactionType = value.transactionType || []
    this.form.status = value.shipmentStatus || []
    this.form.amount = String(value.minimumAmount)
    this.userType.registered = value.isRegistered
    this.userType.unRegistered = value.isUnRegistered
    this.isShipmentId = value.isWithoutShipment
    value.isRegistered && (<string[]>this.form.userType).push('registered')
    value.isUnRegistered && (<string[]>this.form.userType).push('unRegistered')
  }

  @Watch('controller.errUpdate')
  onErrorUpdate(value: string): void {
    if(value !== '' && value === EventBusConstants.UPDATE_GET_POINT_RULES) {
      this.showModalSuccess = true
      this.controller.setErrorUpdate('')
    }
  }
}

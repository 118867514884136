import { UpdateLoyaltyProgramGetPointRules } from '@/data/payload/api/LoyaltyProgramGetPointRules'
import { LoyaltyProgramGetPointRules } from '@/domain/entities/LoyaltyProgramGetPointRules'
import { container } from 'tsyringe'
import Vue from 'vue-ctk-date-time-picker'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import { LoyaltyGetPointRulesPresenter } from '../presenters/LoyaltyProgramGetPointRulesPresenter'
import store from '@/app/ui/store'
import { EventBusConstants } from '@/app/infrastructures/misc'

interface state {
  isLoading: boolean
  formGetPointRule: LoyaltyProgramGetPointRules
}

export interface UpdateLoyaltyProgramGetPointRulesPayload {
  pointRulesId: number
  isRegistered: boolean
  isUnRegistered: boolean
  transactionType: Array<string> | string
  shipmentType: Array<string> | string
  shipmentStatus: Array<string> | string
  minimumAmount: number
  isWithoutShipment: boolean
}


@Module({ namespaced: true, store, name: 'loyaltyProgramGetPointRules', dynamic: true })
class LoyaltyProgramGetPointRulesController extends VuexModule implements state {

  private presenter: LoyaltyGetPointRulesPresenter = container.resolve(LoyaltyGetPointRulesPresenter)

  isLoading = false
  formGetPointRule = new LoyaltyProgramGetPointRules()
  errUpdate = ''

  @Action({ rawError: true })
  public getPointRules(): void {
    this.setLoading(true)
    this.presenter.get()
      .then(resp => {
        this.setDataFormGetPointRules(resp)
      })
      .catch(error => {
        Vue.notify({
          title: 'Get Loyalty Program Point Rules',
          text: error.error?.message?.en || 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updatePointRule(payload: UpdateLoyaltyProgramGetPointRulesPayload): boolean {
    this.setLoading(true)
    this.presenter.update(
      new UpdateLoyaltyProgramGetPointRules(
        payload.pointRulesId,
        payload.isRegistered,
        payload.isUnRegistered,
        payload.transactionType,
        payload.shipmentType,
        payload.shipmentStatus,
        payload.minimumAmount,
        payload.isWithoutShipment
      )
    )
    .then(() => {
      this.setErrorUpdate(EventBusConstants.UPDATE_GET_POINT_RULES)
    })
    .catch(error => {
      const errorMessage = error.status === 400 || error.status === 422
        ? error.error.message.en
        : 'Something wrong'
      this.setErrorUpdate(errorMessage)
      Vue.notify({
        title: 'Update Get Point Rules',
        text: errorMessage,
        type: 'error',
        duration: 5000,
      })
    })
    .finally(() => {
      this.setLoading(false)
    })
    return true
  }

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  private setDataFormGetPointRules(data: LoyaltyProgramGetPointRules): void {
    this.formGetPointRule = data
  }

  @Mutation
  public setErrorUpdate(err: string): void {
    this.errUpdate = err
  }
}

export default getModule(LoyaltyProgramGetPointRulesController)

import { UpdateLoyaltyProgramGetPointRulesInterface } from "../contracts/LoyaltyProgramGetPointRulesRequest"

export class UpdateLoyaltyProgramGetPointRules implements UpdateLoyaltyProgramGetPointRulesInterface {
  private pointRulesId: number
  private isRegistered: boolean
  private isUnregistered: boolean
  private transactionType: string[] | string
  private shipmentType: string[] | string
  private shipmentStatus: string[] | string
  private minimumAmount: number
  private isWithoutWhipment: boolean

  constructor(
    pointRulesId: number,
    isRegistered: boolean,
    isUnRegistered: boolean,
    transactionType: string[] | string,
    shipmentType: string[] | string,
    shipmentStatus: string[] | string,
    minimumAmount: number,
    isWithoutShipment: boolean,
  ) {
    this.pointRulesId = pointRulesId
    this.isRegistered = isRegistered
    this.isUnregistered = isUnRegistered
    this.transactionType = transactionType
    this.shipmentType = shipmentType
    this.shipmentStatus = shipmentStatus
    this.minimumAmount = minimumAmount
    this.isWithoutWhipment = isWithoutShipment
  }

  toPayload(): string {
    return JSON.stringify({
      point_rules_id: this.pointRulesId,
      is_registered: this.isRegistered,
      is_unregistered: this.isUnregistered,
      transaction_type: this.transactionType,
      shipment_type: this.shipmentType,
      shipment_status: this.shipmentStatus,
      minimum_amount: this.minimumAmount,
      is_without_shipment: this.isWithoutWhipment
    })
  }
}
